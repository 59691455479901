import * as React from "react"
import Layout from "components/layout"
import * as styles from "styles/top.module.css"

import { faInstagram, faSoundcloud, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Meta  from "components/meta"


const indexPage = () => (

  
  
  <Layout>
        <Meta
    title="はまちまぐろIndex"
    />

    <h1>Index</h1>
    <div class="profile">
    <p>はまちまぐろ　@_magurochan</p>
    </div>
    <div className={styles.proflink}>
    <div className={styles.proflink_box}><a href="https://Twitter.com/_magurochan" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a></div>
    <div className={styles.proflink_box}><a href="https://instagram.com/_magurochan" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram}/> </a></div>
    <div className={styles.proflink_box}><a href="https://soundcloud.com/magurochan" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faSoundcloud}/></a></div>
    </div>

    <div className={styles.proflink_text}>
    <div className={styles.proflink_big}><a href="https://www.mixcloud.com/magurochan_h/" target="_blank" rel="noopener noreferrer">mixcloud</a></div>
    <div className={styles.proflink_big}><a href="https://magurochan.info/blog/" target="_blank" rel="noopener noreferrer">メインのブログ　ベータ版</a></div>
    <div className={styles.proflink_big}><a href="https://www.youtube.com/channel/UCZPtZ31_MkXoNQBAzaS5baQ" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube}/>YouTube my movies</a></div>
    <div className={styles.proflink_big}><a href="https://www.youtube.com/channel/UC5E3AFGxIyD6xKzGOUHZV9A" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube}/>YouTube works</a></div>
    <div className={styles.proflink_big}><a href="https://magurochan.hatenablog.jp/" target="_blank" rel="noopener noreferrer">はてなブログ</a></div>
    </div>
    
  </Layout>

  /*
  <Layout>
    <Seo title="Home" />
    
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />
    <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </p>
    
  </Layout>
  */
)



export default indexPage
